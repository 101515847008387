// Physiosport custom theme 
// Extends Quark theme scss
// from gulpfile

// Spectre
@import '../../quark/scss/spectre';
@import '../../quark/scss/spectre-exp';
@import '../../quark/scss/spectre-icons';

// Quark theme
@import '../../quark/scss/theme/variables';

// Custom colors 
$green: #00d9bc;
$light-green: #1ce3cc;
$blue: #007ff7;
// $light-color: $light-color;
// $gray-color: $gray-color;
// $dark-color: $dark-color;


@import '../../quark/scss/theme/mixins';
@import '../../quark/scss/theme/framework';
@import '../../quark/scss/theme/typography';
@import '../../quark/scss/theme/forms';
@import '../../quark/scss/theme/mobile';
@import '../../quark/scss/theme/animation';

@import '../../quark/scss/theme/header';
@import '../../quark/scss/theme/footer';
@import '../../quark/scss/theme/menu';

// Extra Skeleton Styling
@import '../../quark/scss/theme/blog';
@import '../../quark/scss/theme/onepage';


// header.scss
#header {
	// border-bottom: 1px solid rgba($gray-color, 0.2);
	// @extend .default-animation;
	background: $blue;
	color: $light-color;
	border-bottom: none;
	a {
	color: $light-color;
	}
	.logo svg path {
	fill: $header-color-dark;
	}
	.header-dark &:not(.scrolled), .header-dark.header-transparent &:not(.scrolled) {
		background: transparent;
		color: $light-color;
		border-bottom: none;
		a {
			color: #fff !important;
		}
		a:hover {
			color: $blue !important;
		}
	}
	.logo {
	  svg, img {
	    height: 30vh;
	    width: auto;
	    max-width: auto;
	    margin-top: 20vh;
	    // max-width: 7rem;
	    display: inherit;
	    @extend .default-animation;
	  }
	}
	body.header-fixed.header-animated &.scrolled {
		.logo {
      		svg, img {
        		height: 2.7rem;
       			margin-top: .3rem;
     		}
    	}
	}
	@media screen and (orientation:portrait) {
  		background-color: $blue !important;
  		height: 30vh;
  		&.scrolled {
  			height: 3.4rem !important;
  		}
  		// transition: none;
  		text-align: center;
  		.navbar-section {
  			// transition: none;
  			display: block;
  			.navbar-brand {
  				display: inline-block;
  				img {
  					margin-top: 0;
  				}
  			}
  		}
  	}
}

// menu.scss
.dropmenu ul li {
	a:focus {
  	color: $light-color !important;
  }
}
#header .dropmenu ul li {
  a:hover, a.active {
  	color: $light-green !important;
  }
}
.mobile-menu {
	display: none;
}
.navbar-section {
	margin: 0 !important;
  @include breakpoint(md) {
    margin: 0 !important;
  }
}


// layout.scss

.column {
	padding-bottom: 1.5rem;
}


// typography.scss
.title-h1h2 {
  h1  {
    margin-bottom: 0.5rem;
    color: $blue;
  }
  h2 + h1, h3 + h1 {
    margin: 0 0 2rem;; 
  }
  h1 + h2 {
  	line-height: 1.3;
  }
  h2, .h2 {
  	margin-bottom: 2rem;
  }
  h3, .h3 {
  	font-size: 1.8rem;
  	margin-bottom: 0.5rem;
  	margin-top: 3rem;
  }
  h3 + h4 {
  	margin: 1rem 0;
  	color: $green;
  }
}

// Hero banner
.hero {
	position: relative;
	background-color: $green;
  padding-top: 97vh;
  padding-bottom: 1rem;
  background-size: auto 100vh;
  background-repeat: no-repeat;
  img {
  	margin-top: 0rem;
  	// max-height: 90vh;
  }
  section.container {
  	position: relative;
  	padding-left:3rem;
  	margin-top:-70vh;
  	text-transform: uppercase;
  	@media screen and (orientation:portrait) {
  		margin-top:-15vh;
  		padding-bottom: 14vh;
  	}
  	p {
  		display: block;
  		margin-bottom: .5rem;
  		text-align: left;
  		font-size: 4.5vh;
  		color: $blue;
  		&:nth-child(2) {
  			color: #fff;
  		}
  	}
  }
  @media screen and (orientation:portrait) {
  	background-size: 120vw auto;
  	margin-top: 30vh;
  	padding-top: 70vh;
  }
}

.modular-features .feature-icon {
  height: auto;
  margin-bottom: 4rem;
  i {
    font-size: 130px;
  }
}
.section {
	&.offset-box {

    .frame-box {
      margin: -3rem (-1rem - $layout-spacing) 3rem;
      padding: 1rem 1rem;
      background: $light-color;
      box-shadow: 0 0 75px 0 rgba($dark-color, 0.1);
    }
  }
}
section#start section.container {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.fond-gris {
	background-color: #eee !important;
}

#athletes + section {

	// background-color: $green !important;
	// color: #fff !important;
	h1 {
		color: $green;
	}
	h3 {
		color: $blue;
	}
	h4 {
		color: $dark-color;
	}
}

// images template
.modular-images {
	text-align: center;
	.images {
		padding-top: 2rem;
		h2 {
			margin-top: 0;
		}
	}
}

// modular cols
.modular-cols {
	padding-bottom: 4rem;
	ul {
		li {
			list-style-type: none;
			border-bottom: $green 1px solid;
		}
	}
	table {
		width: 60%;
		margin: auto;
		border-spacing: 0;
		@include breakpoint(md) {
		  width: 100%;
		}
		td {
			border-bottom: $green 1px solid;
			&:last-child {
				text-align: right;
			}
		}
	}
	h1, h2, h3 {
		text-align: center;
	}
	h2 {
		margin: 4rem 0 1rem;
	}
	h3 {
		color: $green;
		margin: 3rem 0 1rem;
	}
	.column {
		align-self: flex-end;
	}
}

// custom

#footer {
	background-color: $blue !important; 
	color: #fff !important;
	padding-top: 3rem; 
	padding-bottom: 5rem;
}

.nom {
	font-weight: 600;
	color: $green !important;
}
a {
	color: #fff !important;
	text-decoration: none !important;
}
.tel {
	font-weight: 900;
	font-size: 3em;
}
.mel {
	font-size: 1.2em;
}

.modular-video {
	padding-bottom: 4rem;
	.texte-video {
		padding-left: 1.3rem;
	}
}

.video-ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
}

.video-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.banderolle {
	margin-top: 1.5rem;
	// .image {
	// 	text-align: center;
	// }
	// img {
	// 	width:70%;
	// 	height:auto;
	// }
}
.image.border {
	border: 20px #fff solid;
	img {
		display: block;
	}
}

blockquote {
	border: none;
	font-style: italic;
	padding: 2rem;
	position: relative;
	color: $blue;
	&:before {
		content: '“';
		font-size: 5rem;
		font-weight: 300;
		position: absolute;
		left: 0rem;
		top: 0;
		line-height: 1.15;
		color: $green;
	}
	&:after {
		content: '”';
		font-size: 5rem;
		font-weight: 300;
		position: absolute;
		right: 0rem;
		bottom: 0;
		line-height: 0;
		color: $green;
	}
}