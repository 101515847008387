// 360 Degree Viewer
// The number of images 
$image-height: 9rem;
$image-number: 36 !default;
$image-width: 24rem;

.viewer-360 {
  align-items: center;
  display: flex;
  flex-direction: column;
  
  @for $s from 1 through ($image-number) {
    .viewer-slider[value='#{$s}'] + .viewer-image {
      background-position-y: percentage((($s)-1) * 1/(($image-number)-1));
    }
  }

  .viewer-slider {
    cursor: ew-resize;
    margin: 1rem;
    order: 2;
    width: 60%;
  }

  .viewer-image {
    background-position-y: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    height: $image-height;
    order: 1;
    width: $image-width;
  }
}
